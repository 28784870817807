import React, {useEffect, useState} from 'react';
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import AparitieEdit from "../DespreProiect/AparitieEdit";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import JobEdit from "./JobEdit";
import {useLocation} from "react-router-dom";
import axiosInstance from "../../components/Helper/AxiosHelper";

const PaginaJobEdit = () => {


    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pagina modificare aparitie" Pdescription="Aceasta pagina este vizibila doar pentru admin."/>
            <JobEdit />
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaJobEdit;
