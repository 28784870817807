const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Ospatar:',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Bucatar:',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Operator introducere validare baze de date:',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Ospatar:',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'panel',
            rclass : 'pr_50',
            iclass: 'icon_one',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
        },
        {
            id: '2',
            HRtitles : 'Bucatar:',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'layout-grid2',
            rclass : 'pr_50',
            iclass: 'icon_two',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
        },
        {
            id: '3',
            HRtitles : 'Operator introducere validare baze de date: ',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'fullscreen',
            rclass : 'pr_50',
            iclass: 'icon_three',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
        },
        {
            id: '4',
            HRtitles : 'Lucrator in comert: ',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'vector',
            rclass : 'pr_50',
            iclass: 'icon_four',
            l1 : "Curs de nivel 2",
            l2 : "Durata: 360 ore",
            l3 : "O treime reprezinta teorie (120 ore) si doua treimi reprezinta practica (240 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
        },
        {
            id: '5',
            HRtitles : 'Limba spaniola: ',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'cloud-down',
            rclass : 'pr_50',
            iclass: 'icon_five',
            l1 : "Curs de specializare",
            l2 : "Durata: minim 40 ore",
            l3 : "O treime reprezinta teorie (130 ore) si doua treimi reprezinta practica (27 ore)",
            l4 : "Pot participa tinerii din categoriile B, C si D",
        },
        {
            id: '6',
            HRtitles : 'Vrei sa te inscrii? ',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'bolt',
            rclass : 'pr_50',
            iclass: 'icon_six',
            l1 : "Contacteaza-ne pentru detalii:",
            l2 : "neetnomoresudmuntenia@gmail.com ",
            l3 : "",
            l4 : "",
        }
    ],
    rpost:[
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;
