import React, {Component} from 'react';
import {Link} from "react-router-dom";
class BlogGridItem extends Component{
    render(){
        let{date, month, Title, description, btn, comment, image, id}=this.props;
        return(
            <div className="col-lg-4" style={{maxWidth: "100%"}}>
                <div className="blog_list_item blog_list_item_two">
                    {/*<a href="/#" className="post_date">*/}
                    {/*    <h2>{date} <span>{month}</span></h2>*/}
                    {/*</a>*/}
                    {image === "" && (
                        <img src={require ("../../img/new/blog/img.png")} height="300px" width="370" alt=""/>

                    )}
                    {image !== "" && (
                        <img src={image} height="300px" width="370" alt="" loading="lazy"/>

                    )}
                    <div className="blog_content">
                        <Link to={"/blog/" + id}>
                            <h5 className="blog_title">{Title}</h5>
                        </Link>
                        {/*<p>{description}</p>*/}
                        <div className="post-info-bottom">
                            <Link to={"/blog/" + id} className="learn_btn_two">
                                {btn} <i className="arrow_right"></i>
                            </Link>
                            {/*<a href="/#" className="learn_btn_two">{btn} <i className="arrow_right"></i></a>*/}
                            {/*<a className="post-info-comments" href="/#">*/}
                            {/*    <i className="icon_comment_alt" aria-hidden="true"></i>*/}
                            {/*    <span>{comment} Comments</span>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BlogGridItem;
