import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class CustomNavbar extends Component {

    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/' className="dropdown-toggle nav-link">Despre proiect</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact className="nav-link" >Obiective & Grup Tinta</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Obiective specifice ale proiectului</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Activitati</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Documente/Metodologii relevante</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Aparitii in presa</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/Informare' className="dropdown-toggle nav-link">Informare</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact className="nav-link" >Inscrierea in proiect</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Perioada de desfasurare</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Evenimente</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >AJOFM-uri partenere</NavLink></li>
                                        <li className="nav-item"><NavLink exact className="nav-link" >Profilare</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/Formare-Anteprenoriala' className="dropdown-toggle nav-link">Formare Anteprenoriala</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact className="nav-link" >Cursul de anteprenoriat</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Cursuri-De-Calificare">Cursuri</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Medierea-Muncii">Medierea muncii</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Sectiunea-Blog">Blog</NavLink></li>

                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
        );
    }
}

export default CustomNavbar;
