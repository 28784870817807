import React, {useEffect, useState} from 'react';
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import BlogGrid from "../components/Blog/BlogGrid";
import Blogsingle from "../components/Blog/Blogsingle";
import Navbar from "./Navbar";
import ListaBloguri from "./Blog/ListaBloguri";
import axiosInstance from "../components/Helper/AxiosHelper";

const SectiuneaBlog = () => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchData() {
        axiosInstance.get('/api/news/all').then((response) => {
            setBlogs(response.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (isLoading) {
        return <div></div>;
    }
    return(
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Blog" Pdescription=""/>
            {/*<BlogGrid/>*/}
            {/*<Blogsingle/>*/}
            <ListaBloguri blogs={blogs} setBlogs={setBlogs}/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
};

export default SectiuneaBlog;
