import React, {Component} from 'react';

class ServiceSubscribe extends Component {
    render(){
        let {sClass} = this.props;
        return(
            <div className="container" style={{paddingBottom: "200px", paddingTop: "50px"}}>
                 <div className={`subscribe_form_info text-center ${sClass}`}>
                     <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">Cursul de antreprenoriat</h2>
                     <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
                         <p align="justify">Acest curs este destinat unui numar de 28 tineri Neets din categoria A care vor intra atat in programul de formare antreprenoriala derulat cat si in etapa de consiliere. Derularea programului de FPC cu o durata de min 40 ore, dintre care 2/3 pregatire practica si 1/3 pregatire teoretica.
                         </p>

                         <p align="justify">
                             Dupa finalizarea cursului, cei 28 de participant vor depune planurile de afaceri in cadrul concursului de antreprenoriat. In urma jurizarii vor fi selectati  15 tineri Neets ale caror planuri de afaceri vor fi selectate ca urmare a competitiei derulata in proiect si care vor beneficia ulterior de mentorat, sprijin pentru infiintarea afacerilor si sprijin financiar in valoare de 120.000 lei pentru implementare.

                         </p>
                     </div>

                     {/*<form action="#" className="subscribe-form">*/}
                     {/*    <input type="text" className="form-control" placeholder="Your email"/>*/}
                     {/*    <button type="submit" className="btn_hover btn_four mt_40">Subscribe</button>*/}
                     {/*</form>*/}
                 </div>
            </div>
         )
    }
}
export default ServiceSubscribe;
