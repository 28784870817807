import React from 'react';
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import HRService from "../components/Service/HRService";
import ServiceData from "../components/Service/ServiceData";
import PrototypeService from "../components/Service/Sservice/PrototypeService";
import Partner from "../components/Partner";
import ServiceSubscribe from "../components/ServiceSubscribe";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import Navbar from "./Navbar";

const CursuriDeCalificare = () => {
    return(
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Cursuri pentru tine" Pdescription=""/>
            <HRService ServiceData={ServiceData}/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
};

export default CursuriDeCalificare;
