import React from 'react';
import FileDownload from "js-file-download";
import axiosInstance from "../../components/Helper/AxiosHelper";
import Sectitle from "../../components/Title/Sectitle";
import Featuresitems from "../../components/Features/Featuresitems";

const SectiuniDocumente = ({users}) => {

    const downloadFile = (user) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/files/download/${user.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(user)
            const file = res.data;
            FileDownload(file, user.fileName, file.type)
        })
    }

    return (
        <>
            <section className="blog_area" >
                <div className="container">
                    <br/><br/><br/>
                    <Sectitle Title="Documente incarcate:" TitleP=""
                              tClass="t_color3" sClass="sec_title text-center"/>
                    {/*<div className="features_info">*/}
                    {/*</div>*/}
                    <div className="row">
                        {users.map((user) => {
                            return (
                                <div className="col-lg-6" key={user.id} style={{paddingBottom: "30px"}}>
                                    <div className="event_features_item" style={{padding: "0px", height: "130px", paddingTop: "10px", paddingLeft: "30px"}}>
                                        <h3>#{user.id} {user.lastName} {user.firstName}</h3>
                                        <div className="row align-items-center ">

                                            {user.isBusinessPlan && (
                                                <label onClick={downloadFile(user)} style={{cursor:"pointer"}}>
                                                    <i className="icon_download"></i> Descarca planul de afaceri
                                                </label>
                                            )}
                                            {!user.isBusinessPlan && (
                                                <label>
                                                    <i className="icon_error-triangle"></i> Nu exista documente incarcate
                                                </label>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
        </>
    );
};

export default SectiuniDocumente;
