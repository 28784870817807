import React, {useEffect, useState} from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import FileDownload from "js-file-download";
import Quill from "../../components/Quill";

const DocMetodologieEdit = ({doc}) => {
    const [isShown, setIsShown] = useState(false);
    const [title, setTitle] = useState(doc.title);
    const [isPublic, setIsPublic] = useState(doc.isPublic);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [file, setFile] = useState();
    const [fileToSend, setFileToSend] = useState(null);

    async function fetchData() {
        await axiosInstance.get('/api/docfile/id', {
            params: {
                id: doc.id
            },
            // responseType:"blob"
        }).then(r => {
            console.log(r.data)
            setFile(r.data);
            // setLoading(false);
        })
    }

    useEffect(() => {

        fetchData()

    },[]);

    const showEditor = (e) => {

        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };

    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        // const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const eventJson = JSON.stringify({
            title : title,
            // text : stringHtml,
            // link : link,
            isPublic : isPublic,
        })
        try {

            const response = await axiosInstance.patch('/api/doc/update', eventJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: doc.id
                }
            })
            const data1 = await response;

        } catch(error) {
            console.log(error)
        }
    }


    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function selectMaterialStatus() {
        setIsPublic(!isPublic);
    }

    // function handleLinkChange(e) {
    //     setLink(e.target.value);
    // }

    const handleFileSubmit = async(e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("file", fileToSend);
        formData.append("id", doc.id);
        try {
            const response = await axiosInstance.patch('/api/doc/add/file', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })
        } catch(error) {
            console.log(error)
        }
        axiosInstance.get('/api/doc/id', {
            params: {
                id: doc.id
            },
        }).then(r => {
            setFile(r.data);
        })

    }

    function uploadFile(e) {
        setFileToSend(e.target.files[0]);
    }

    const downloadFile = (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/docfile/download/${doc.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, doc.fileName, res.data.type)
        })
    }

    return (

        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item" style={{padding: "0px", height: "40%", paddingTop: "50px" }}>
                                <form onSubmit={updateText} align="center" >
                                    <label htmlFor="filePicker3" style={{cursor:"pointer"}} >
                                        <div onClick={selectMaterialStatus}>
                                            {isPublic
                                                ? <h1 className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "red"}}><i className="icon_circle-slelected"></i>Ascunde Element</h1>
                                                : <h1 className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "green"}}><i className="icon_circle-slelected"></i>Publica Element</h1>

                                            }
                                        </div>
                                        <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                    </label>
                                </form>
                            </div>
                        )}
                        <br/>
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item " style={{padding: "0px", height: "30%", paddingTop: "30px"}}>
                                <form onSubmit={handleFileSubmit} style={{paddingLeft: "30px"}}>
                                    <label htmlFor="filePicker"  style={{cursor:"pointer"}}>
                                        <i className="icon_upload"></i>Incarca fisier&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                        <button type="submit" >
                                            Save
                                        </button>
                                    </label>

                                </form>
                            </div>

                        )}
                        <br/>
                        <div className="event_features_item " style={{padding: "0px", height: "30%", paddingTop: "10px", paddingLeft: "30px"}}>
                            Document incarcat:
                            {file && (
                                <div onClick={downloadFile} style={{cursor:"pointer"}}>
                                    <i className="icon_document"></i>{" "}{doc.fileName}
                                </div>
                            )}
                        </div>


                    </div>
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <div className="blog_content">
                                {isTitleEditable && (
                                    <form onSubmit={updateText}  align="center">
                                        <label htmlFor="filePicker1">
                                            <input id="filePicker1" type="text" placeholder={title} name="file1" onChange={handleTitleChange}>
                                            </input>
                                            <button type="submit" >
                                                Save
                                            </button>
                                        </label>
                                    </form>
                                )}
                                {!isTitleEditable && (
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30" onClick={() => setIsTitleEditable(!isTitleEditable)} align="center">{title}</h5>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default DocMetodologieEdit;
