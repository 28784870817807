import React, {Component} from 'react';
import Reveal from 'react-reveal'
class HRServiceItems extends Component{
    render(){
        var{HRtitle, HRdescription, Hicon, rclass, iclass, l1, l2, l3, l4} = this.props;
        return(
                <div className="col-lg-4 col-sm-6">
                    {/*<Reveal bottom cascade>*/}
                    <div className={`p_service_item ${rclass}`}>
                        <div className={`icon ${iclass}`}><i className={`ti-${Hicon}`}></i></div>
                        {l3 === "" && (
                            <>
                                <h5 className="f_600 f_p t_color3" style={{color: "red"}}>{HRtitle}</h5>
                                <p className="f_400" style={{marginBottom: '0', color: "red"}}>{l1}</p>
                                <p className="f_400" style={{marginBottom: '0', color: "red"}}>{l2}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{l3}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{l4}</p>
                            </>

                        )}
                        {l3 !== "" && (
                            <>
                                <h5 className="f_600 f_p t_color3">{HRtitle}</h5>
                                <p className="f_400" style={{marginBottom: '0'}}>{l1}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{l2}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{l3}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{l4}</p>
                            </>
                        )}

                    </div>
                    {/*</Reveal>*/}
                </div>

        )
    }
}
export default HRServiceItems;
