import React from 'react';
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import ListaBloguri from "../Blog/ListaBloguri";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import FormularIncarcare from "./FormularIncarcare";

const PaginaIncarcaPlanDeAfaceri = () => {
    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Sectiune incarcare" Pdescription="In aceasta sectiune iti poti incarca planul de afaceri"/>
            <FormularIncarcare/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    );
};

export default PaginaIncarcaPlanDeAfaceri;
