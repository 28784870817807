import React, { Component } from 'react';

class JobComponentaDinamica extends Component {
    render() {
        let title = this.props.title;
        let description = this.props.description;
        let slug = this.props.slug;
        let id = this.props.id;
        let link = this.props.link
        return (

                                <div className="media">
                                    <div className="media-left">
                                        <img
                                            src={require("../../img/process/logo-slack.png")}
                                            alt=""
                                            width="40px"
                                            height="40px"
                                        />
                                        <a href={`https://www.ejobs.ro/user/locuri-de-munca/${slug}/${id}`} target="_blank">
                                            <i className="icon_link"></i>Vezi detalii
                                        </a>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h_head">{title}</h3>
                                        <p>{description}</p>
                                    </div>
                                </div>

        );
    }
}

export default JobComponentaDinamica;
