import React, {useEffect, useState} from 'react';
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import SectiuniDocumente from "./SectiuniDocumente";
import BorderSpinner from "./BorderSpinner";
import axiosInstance from "../../components/Helper/AxiosHelper";

const PaginaDocumenteAdmin = () => {
    const [isLoading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get('/api/user/all');
                if (!(response.status === 200)) {
                    setIsError(true);
                    throw new Error(`Error! status: ${response.status}`);
                }
                const res = await response;
                setUsers(res.data);
                setLoading(false);
            } catch (error) {
                setIsError(true);
                console.log(error);
            }

        }
        fetchData();
    }, []);

    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pagina documente admin" Pdescription="In aceasta sectiune vezi documentele incarcate de utilizatori"/>
            {isError && (
                <section className="appie-blog-3-area pt-90 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-sidebar" align="center">
                                    <h3 style={{color: 'red'}}><i className="icon_error-triangle" /></h3>
                                    <h3>A aparut o eroare! Contacteaza echipa suport!</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {!isError && isLoading && (
                <section className="appie-blog-3-area pt-90 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="appie-section-title text-center">
                                    <h3 className="appie-title">Documentele se incarca:</h3>
                                </div>
                                <div className="appie-section-title text-center">
                                    <BorderSpinner />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            )}
            {!isError && !isLoading && (
                <SectiuniDocumente users={users} />
            )}
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    );
};

export default PaginaDocumenteAdmin;
