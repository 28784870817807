import React from 'react';
import CustomNavbar from "../components/CustomNavbar";
import AgencyBanner from "../components/Banner/AgencyBanner";
import BannerData from "../components/Banner/BannerData";
import ErpFeatures from "../components/Features/ErpFeatures";
import ChatClients from "../components/chatClients";
import EventFeatures from "../components/Features/EventFeatures";
import AgencyAbout from "../components/About/AgencyAbout";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import ServiceSubscribe from "../components/ServiceSubscribe";
import SecurityAction from "../components/SecurityAction";
import DocumenteConcursPlan from "./DespreProiect/DocumenteConcursPlan";
import Navbar from "./Navbar";
import {Element} from "react-scroll";
import DocumenteRelevante from "./DocumenteRelevante";

const FormareAntreprenoriala = () => {

    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title="FORMARE ANTREPRENORIALA" description="" classBG="formare_antreprenoriala"/>
            <Element id='cursul' name='cursul'>
                <ServiceSubscribe sClass="s_form_info_two"/>
            </Element>

            <SecurityAction />
            {/*<DocumenteConcurs />*/}
            {/*<DocumenteConcursPlan sClass="s_form_info_two"/>*/}
            <DocumenteRelevante/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
};

export default FormareAntreprenoriala;
