import React from 'react';
import FileDownload from "js-file-download";
import axiosInstance from "./Helper/AxiosHelper";

const SecurityAction =()=>{

    function downloadFile(e) {

        e.preventDefault();
        let fileName = "";
        if (e.target.id === 'doc1') {
            fileName = "Metodologie selectie PA_Neet No More_SM_154910 vf.pdf";
        }
        else if (e.target.id === 'doc2') {
            fileName = "LISTA Rezultate finale Faza evaluare A_administrativ.pdf";
        }
        else if (e.target.id === 'doc3') {
            fileName = "LISTA Rezultate finale Faza B_tehnic si calitativ.pdf";
        }
        else if (e.target.id === 'doc4') {
            fileName = "LISTA finala a planurilor de afaceri selectate la finantare.pdf";
        }
        else if (e.target.id === 'doc5') {
            fileName = "Procedura pentru semnarea contractului de subventie_154910.pdf";
        }
        else {
            fileName = e.target.innerText;
        }
        axiosInstance.get(`/api/files/downloads/${fileName}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, `${fileName}`)
        })
    }

    return(
        // <section className="security_action_area">
        //     <img className="p_absoulte img_bg" src={require("../img/home-security/sheld_bg.png")} alt=""/>
            <>
                <div className="container" style={{paddingTop: "50px", paddingBottom: "10px", borderRadius: "2px"}}>
                    <div className="security_action_inner">
                        <div className="media">
                            <img src={require("../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                        </div>
                        <div className="media-body align-items-center">
                            {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                            <h3 align="center">Metodologie selectie Planuri de Afaceri</h3>
                        </div>
                        <div className="er_btn er_btn_two" id="doc1" onClick={downloadFile} style={{cursor: "pointer"}}>
                            Descarca
                        </div>
                        {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
                    </div>
                </div>
                <div className="container" style={{paddingTop: "10px", paddingBottom: "10px", borderRadius: "2px"}}>
                    <div className="security_action_inner">
                        <div className="media">
                            <img src={require("../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                        </div>
                        <div className="media-body align-items-center">
                            {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                            <h3 align="center">Lista rezultate finale Faza A – Administrativ</h3>
                        </div>
                        <div className="er_btn er_btn_two" id="doc2" onClick={downloadFile} style={{cursor: "pointer"}}>
                            Descarca
                        </div>
                        {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
                    </div>
                </div>
                <div className="container" style={{paddingTop: "10px", paddingBottom: "10px", borderRadius: "2px"}}>
                    <div className="security_action_inner">
                        <div className="media">
                            <img src={require("../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                        </div>
                        <div className="media-body align-items-center">
                            {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                            <h3 align="center">Lista rezultate finale Faza B – Tehnic si Calitativ</h3>
                        </div>
                        <div className="er_btn er_btn_two" id="doc3" onClick={downloadFile} style={{cursor: "pointer"}}>
                            Descarca
                        </div>
                        {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
                    </div>
                </div>
                <div className="container" style={{paddingTop: "10px", paddingBottom: "10px", borderRadius: "2px"}}>
                    <div className="security_action_inner">
                        <div className="media">
                            <img src={require("../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                        </div>
                        <div className="media-body align-items-center">
                            {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                            <h3 align="center">LISTA finala a planurilor de afaceri selectate la finantare</h3>
                        </div>
                        <div className="er_btn er_btn_two" id="doc4" onClick={downloadFile} style={{cursor: "pointer"}}>
                            Descarca
                        </div>
                        {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
                    </div>
                </div>
                <div className="container" style={{paddingTop: "10px", paddingBottom: "50px", borderRadius: "2px"}}>
                    <div className="security_action_inner">
                        <div className="media">
                            <img src={require("../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                        </div>
                        <div className="media-body align-items-center">
                            {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                            <h3 align="center">Procedura pentru semnarea contractului de subventie_154910</h3>
                        </div>
                        <div className="er_btn er_btn_two" id="doc5" onClick={downloadFile} style={{cursor: "pointer"}}>
                            Descarca
                        </div>
                        {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
                    </div>
                </div>
            </>


        // </section>
    )
}
export default SecurityAction;
