import React, {useEffect, useState} from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import AparitieEdit from "../DespreProiect/AparitieEdit";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import BlogEdit from "./BlogEdit";

const PaginaBlog = () => {
    const [blog, setBlog] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = window.location.pathname;
    const idParam = location.substring(6)
    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/news/id', {
                params: {
                    id: idParam
                },
                // responseType:"blob"
            }).then(r => {
                setBlog(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle={blog.title} Pdescription=""/>
            <BlogEdit blog={blog}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaBlog;
