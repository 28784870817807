import React from 'react';
import PaginaDocMetodologieEdit from "../DespreProiect/PaginaDocMetodologieEdit";
import PaginaEvenimentEdit from "../Informare/PaginaEvenimentEdit";

const ProtectedEvenimentRoute = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaEvenimentEdit />
    }
    window.location.pathname = "/";
};

export default ProtectedEvenimentRoute;
