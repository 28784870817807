import React from 'react';
import PaginaJobEdit from "../Joburi/PaginaJobEdit";

const ProtectedJobRoute = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaJobEdit />
    }
    window.location.pathname = "/";

};

export default ProtectedJobRoute;
