import React, {useEffect, useState} from 'react';
import BlogGridItem from "../../components/Blog/BlogGridItem";
import axiosInstance from "../../components/Helper/AxiosHelper";
import { baseURL } from '../../components/Helper/AxiosHelper'

const ListaBloguri = ({blogs, setBlogs}) => {

    const [images, setImages] = useState([]);
    // useEffect(() => {
    //     blogs && blogs.forEach((element) => {
    //         if (element.isImage) {
    //             fetchImages(element.id)
    //         }
    //     })
    //
    //     // setIsLoading(false)
    // }, [blogs])



    async function fetchImages(id) {
        axiosInstance.get('/api/newsimage/id', {
            params: {
                id: id
            }
        }).then(response => {
            if (images.length === 0) {
                response.data && setImages(images => [...images, response.data])
            }
            response.data && images.some(e => e === response.data) &&
            setImages(images => [...images, response.data])
        }).catch((e) => console.log(0, e));

    }
    const addNewBlog = async(e) => {
        e.preventDefault();
        const blog = JSON.stringify({
            title : "Titlul noutatii",
            text : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic : false,
        })
        try {
            await axiosInstance.post('/api/news/add', blog,{
                headers: {"Content-Type": "application/json"}
            })

        } catch(error) {
            console.log(error)
        }
        getAllCourses();
    }

    const getAllCourses = () => {
        axiosInstance.get('/api/news/all').then((response) => {
            setBlogs(response.data);
        });
    }

    // function fetchImage(course) {
    //     axiosInstance.get('/api/newsimage/id', {
    //         params: {
    //             id: course.id
    //         }
    //     }).then(response => {
    //         // setImages(images => [...images, response.data])
    //         return `data:${response.data.type};base64,${response.data.data}`;
    //     }).catch((e) => console.log(0, e));
    //
    // }

    return (
        <section className="blog_area_two sec_pad">
            {localStorage.getItem("role") === "admin" && (
                <div style={{textAlign: "center"}}>
                    <img src={require ("../../img/home4/addnew.png")} width="60px" height="60px" alt="" style={{cursor: "pointer"}} onClick={addNewBlog}/>
                    <p>Adauga</p>
                </div>

            )}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 blog_grid_info">
                        <div className="row">
                            {blogs.map((blog, index) => {
                                return (
                                    <>
                                        {localStorage.getItem('role') === 'admin' && (
                                            <div key={blog.id}>
                                                {/*<BlogGridItem date="14" month="jan" image="" Title={blog.title} description={blog.text}*/}
                                                {/*              btn="Vezi mai mult" comment="3" id={blog.id} key={index}/>*/}
                                                {!blog.isImage && (
                                                    <BlogGridItem date="14" month="jan"
                                                                  image=""
                                                                  Title={blog.title} description={blog.text}
                                                                  btn="Vezi mai mult" comment="3" id={blog.id} />
                                                )}
                                                {blog.isImage && (
                                                    <BlogGridItem date="14" month="jan" id={blog.id}
                                                                  image={`${baseURL}/api/newsimage/${blog.id}`}
                                                                  Title={blog.title} description={blog.text}
                                                                  btn="Vezi mai mult" comment="3" key={blog.id}/>

                                                 )}
                                            </div>
                                        )}
                                        {blog.isPublic && localStorage.getItem('role') !== 'admin' && (
                                            <div key={blog.id}>
                                                {/*<BlogGridItem date="14" month="jan" image="" Title={blog.title} description={blog.text}*/}
                                                {/*              btn="Vezi mai mult" comment="3" id={blog.id} key={index}/>*/}
                                                {!blog.isImage && (
                                                    <BlogGridItem date="14" month="jan"
                                                                  image=""
                                                                  Title={blog.title} description={blog.text}
                                                                  btn="Vezi mai mult" comment="3" id={blog.id} />
                                                )}
                                                {blog.isImage && (
                                                    <BlogGridItem date="14" month="jan" id={blog.id}
                                                                  image={`${baseURL}/api/newsimage/${blog.id}`}
                                                                  Title={blog.title} description={blog.text}
                                                                  btn="Vezi mai mult" comment="3" key={blog.id}/>

                                                )}
                                                {/*{blog.isImage && images.map((element) => {*/}
                                                {/*    return (*/}
                                                {/*        <>*/}
                                                {/*            {element.newsMnt.id === blog.id && (*/}
                                                {/*                <BlogGridItem date="14" month="jan" id={blog.id} image={`data:${element.type};base64,${element.data}`} Title={blog.title} description={blog.text}*/}
                                                {/*                              btn="Vezi mai mult" comment="3" key={element.id}/>*/}
                                                {/*            )}*/}
                                                {/*        </>*/}
                                                {/*    )*/}
                                                {/*})}*/}
                                            </div>
                                        )}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ListaBloguri;
