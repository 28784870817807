import React, {useEffect, useState} from 'react';
import Sectitle from "../../components/Title/Sectitle";
import ScheduleTab from "../../components/scheduleTab";
import JobComponentaDinamica from "./JobComponentaDinamica";
import JoburiAuto from "./JoburiAuto";
import Joburi from "./Joburi";
import axiosInstance from "../../components/Helper/AxiosHelper";

const PaginaJoburi = () => {
    const [isJoburiAuto, setIsJoburiAuto] = useState(false);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/job/all').then((response) => {
            setJobs(response.data);
        })
    }, []);

    function showJobs() {
        setIsJoburiAuto(!isJoburiAuto);
    }

    return (
        <section className="service_details_area sec_pad ">
            <div className="container">
                <Sectitle Title="Locuri de munca" TitleP=""
                          tClass="t_color3" sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-12">
                        <Joburi jobs={jobs} setJobs={setJobs} />
                    </div>
                </div>

                    <div className="row" style={{paddingTop: "50px"}}>
                        <div className="col-lg-12" style={{textAlign: "center"}}>
                            <p onClick={showJobs} className="chat_btn btn_hover" >
                                Vezi mai multe job-uri
                            </p>
                        </div>
                    </div>
                {isJoburiAuto && (
                    <div className="row">
                        <div className="col-lg-12">
                            <JoburiAuto/>
                        </div>
                    </div>
                )}
            </div>


        </section>
    );
};

export default PaginaJoburi;
