import React from 'react';
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import SignInForm, {userAtom} from "../components/SignInForm";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import {useAtom} from "jotai";
import Navbar from "./Navbar";

const PaginaLogin = () => {

    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Conectare la platforma" Pdescription=""/>
            <SignInForm/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaLogin;
