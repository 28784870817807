import React from 'react';
import Sectitle from "./Title/Sectitle";

const chatClients =()=>{
    return(
        <div>
            <section className="chat_clients_area">
                <div className="container-fluid">
                    <Sectitle Title="Evenimente organizate pentru atragerea Grupului Tinta" TitleP=""
                              tClass="t_color3" sClass="sec_title text-center mb_70"/>

                    <div className="row">
                        <div className="col-lg-6 p-0 chat_clients_feedback">
                            <div className="clients_feedback_item flex-row-reverse">
                                <div className="feedback_body">
                                    <h3 className="f_name">Online</h3>
                                    {/*<a href=".#">hello.com</a>*/}
                                    <p align="justify">
                                        se vor derula sesiuni de informare in format online prin paginile de social media, anunturi pe grupuri de profil, anunturi pe retelele de socializare, contactare prin telefon.
                                    </p>
                                </div>
                                <img src={require("../img/home-chat/woman-showing-facebook-icon-remo.png")} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0 chat_clients_feedback feedback_two">
                            <div className="clients_feedback_item">
                                <div className="feedback_body">
                                    <h3 className="f_name">Offline</h3>
                                    {/*<a href=".#">hello.com</a>*/}
                                    <p align="justify">
                                        se vor realiza deplasari in teritoriu (in cele 7 judete componente ale Regiunii Sud Muntenia, prin realizarea unor evenimente dar si a unor intalniri informale de informare la nivel de comunitate, diseminarea de flyere/brosuri si alte materiale cu informatii.                                     </p>
                                </div>
                                <img src={require("../img/home-chat/smiling-friendly-corporate-woman-removebg-preview.png")} width="350" height="450" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="chat_get_started_area">*/}
            {/*    <div className="container">*/}
            {/*        <div className="chat_get_started_content text-center">*/}
            {/*            <h2 className="wow fadeInUp" data-wow-delay="0.2s">Get started with us</h2>*/}
            {/*            <p>A better experience for your customers, fewer headaches for your team. You'll be set up in minutes.</p>*/}
            {/*            <a href=".#" className="chat_btn btn_hover">Get Started</a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>
    )
}
export default chatClients;
