import React, {useState} from 'react';
import JobComponentaDinamica from "./JobComponentaDinamica";
import axiosInstance from "../../components/Helper/AxiosHelper";
import JobCompDinamicaManual from "./JobCompDinamicaManual";

const Joburi = ({jobs, setJobs}) => {

    const [isPublic, setIsPublic] = useState(false);
    function selectJobStatus() {
        setIsPublic(!isPublic);
    }

    const addNewJob = async(e) => {
        e.preventDefault();
        const job = JSON.stringify({
            title: "Titlu job",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false,
            link: 'Link'
        })
        try {

            const response = await axiosInstance.post('/api/job/add', job, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllCompanies();
    }

    const getAllCompanies = () => {

        axiosInstance.get('/api/job/all').then((response) => {
            console.log(response.data)
            setJobs(response.data);
        });
    }

    return (
        <section className="event_schedule_area sec_pad">
            {localStorage.getItem("role") === "admin" && (
                <div style={{textAlign: "center"}}>
                    <img src={require ("../../img/home4/addnew.png")} width="60px" height="60px" alt="" style={{cursor: "pointer"}} onClick={addNewJob}/>
                    <p>Adauga</p>
                </div>

            )}
            <div className="container">
                <div className="event_schedule_inner">
                    <div className="tab-content event_tab_content">
                        {localStorage.getItem('role') === 'admin' && (
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {jobs.map((job) => {
                                    return (
                                        <JobCompDinamicaManual id={job.id} title={job.title} description={job.description} link={job.link} key={job.id}/>
                                    )
                                })}
                            </div>
                        )}
                        {localStorage.getItem('role') !== 'admin' && (
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {jobs.map((job) => {
                                    if (job.isPublic) {
                                        return (
                                            <JobCompDinamicaManual id={job.id} title={job.title} description={job.description} link={job.link} key={job.id}/>
                                        )
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Joburi;
