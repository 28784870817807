import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {Link} from "react-router-dom";
const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />
          <h2>Eroare. Pagina nu este gasita.</h2>
          <p>
            Ne cerem scuze. Du-te la sectiunea "Despre proiect" pentru a afla mai multe despre NEET...{" "}
          </p>
          {/*<form action="#" className="search">*/}
          {/*  <input type="text" className="form-control" placeholder="search" />*/}
          {/*</form>*/}
          <Link to="/" className="about_btn btn_hover">
            Inapoi la Despre proiect <i className="arrow_right"></i>
          </Link>
        </div>
      </div>
    </section>
    <FooterTwo FooterData={FooterData} />
  </div>
);
export default NotFound;
