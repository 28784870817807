import React, {useEffect, useState} from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import Quill from "../../components/Quill";

const JobEdit = () => {
    const [isShown, setIsShown] = useState(false);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isLinkEditable, setIsLinkEditable] = useState(false);

    const [job, setJob] = useState(null);
    const location = window.location.pathname;
    const idParam = location.substring(5)

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/job/id', {
                params: {
                    id: idParam
                },
            }).then(r => {
                setJob(r.data);
            })
        }
        fetchData()

    }, []);


    const showEditor = (e) => {
        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };
    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        setIsLinkEditable(false);
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const jobJson = JSON.stringify({
            title : job.title,
            description : stringHtml,
            link : job.link,
            isPublic : job.isPublic,
        })
        try {

            const response = await axiosInstance.patch('/api/job/update', jobJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: job.id
                }
            })
            const data1 = await response;
            setJob(data1.data)
        } catch(error) {
            console.log(error)
        }
    }
    function handleTitleChange(e) {
        setJob({ ...job, title: e.target.value })
    }
    async function selectMaterialStatus(e) {
        e.preventDefault()
        const jobJson = JSON.stringify({
            title : job.title,
            description : job.description,
            link : job.link,
            isPublic : !job.isPublic,
        })
        try {
            const response = await axiosInstance.patch('/api/job/update', jobJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: job.id
                }
            })
            const data1 = await response;
            setJob(data1.data)
        } catch(error) {
            console.log(error)
        }
    }
    function handleLinkChange(e) {
        // setLink(e.target.value);
        setJob({ ...job, link: e.target.value })
    }

    if (!job) {
        return <div></div>;
    }

    return (
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item" style={{padding: "0px", height: "130px", paddingTop: "50px" }} align="center">
                                <div onClick={selectMaterialStatus} style={{cursor:"pointer"}} >
                                    {job.isPublic
                                        ? <h1 name="isPublic"  className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "red"}}><i className="icon_circle-slelected"></i>Ascunde Job</h1>
                                        : <h1 name="isPublic"  className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "green"}}><i className="icon_circle-slelected"></i>Publica Job</h1>

                                    }
                                </div>
                            </div>
                        )}
                        <br/>
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item " style={{padding: "0px", height: "90px", paddingTop: "30px", paddingLeft: "20px"}}>
                                <form onSubmit={updateText}>
                                    {isLinkEditable && (
                                        <label htmlFor="filePicker4">
                                            <i className="icon_link"></i>&nbsp;&nbsp;&nbsp;
                                            <input id="filePicker" type="text" name="file4" placeholder={job.link} style={{maxWidth: '190px'}} onChange={handleLinkChange}></input>
                                            <button type="submit" >
                                                Save
                                            </button>
                                        </label>
                                    )}
                                    {!isLinkEditable && (
                                        <div onClick={() => setIsLinkEditable(!isLinkEditable)}>
                                            <i className="icon_link"></i>&nbsp;&nbsp;&nbsp;{job.link}
                                        </div>
                                    )}

                                </form>
                            </div>

                        )}
                    </div>
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <div className="blog_content">
                                {isTitleEditable && (
                                    <form onSubmit={updateText} align="center">
                                        <label htmlFor="filePicker1">
                                            <input id="filePicker1" type="text" placeholder={job.title} name="file1" onChange={handleTitleChange}>
                                            </input>
                                            <button type="submit" >
                                                Save
                                            </button>
                                        </label>
                                    </form>
                                )}
                                {!isTitleEditable && (
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30" onClick={() => setIsTitleEditable(!isTitleEditable)} align="center">{job.title}</h5>
                                )}

                                <div>
                                    {isShown && (
                                        <div>
                                            <Quill
                                                actualText={{
                                                    container: document.getElementById('tryEdit'),
                                                }}
                                            />
                                            <button type="submit" onClick={showEditor}>
                                                Save
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: job.description}}>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default JobEdit;
