import React, { Component } from "react";

class EventFeaturesItem extends Component {
    render(){
        let{url, title, p1, p2, p3, p4, p5, btn, image}=this.props;
        return(
            <div className="col-lg-4 col-sm-6" style={{paddingBottom: "30px"}}>
                <div className="event_features_item wow fadeInUp">
                    <img src={require ("../../img/home-event/" + image)} alt="" width="40px" height="40px"/>
                    <h6>{title}</h6>
                    <p>{p1}</p>
                    <p>{p2}</p>
                    <p>{p3}</p>
                    <p>{p4}</p>
                    <p>{p5}</p>
                    {/*<p>{ptext}</p>*/}
                    {/*<a href={url} className="e_features_btn">{btn} <i className="icon_plus"></i></a>*/}
                </div>
            </div>
        )
    }
}

export default EventFeaturesItem;
