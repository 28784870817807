import React, {useEffect, useState} from 'react';
import FileDownload from "js-file-download";
import axiosInstance from "../../components/Helper/AxiosHelper";
import Quill from "../../components/Quill";

const BlogEdit = ({blog}) => {
    const [isShown, setIsShown] = useState(false);
    const [title, setTitle] = useState(blog.title);
    const [text, setText] = useState(blog.text);
    const [isPublic, setIsPublic] = useState(blog.isPublic);
    // const [newsType, setNewsType] = useState(course.newsType);
    // const [format, setFormat] = useState(course.format);
    // const [image, setImage] = useState(`data:${course.type};base64,${course.data}`);
    const [image, setImage] = useState(null);
    const [imageToSend, setImageToSend] = useState();
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    // const [isNewsTypeEditable, setIsNewsTypeEditable] = useState(false);
    // const [isFormatEditable,setIsFormatEditable] = useState(false);
    const [blogFiles, setBlogFiles] = useState([]);
    const [fileToSend, setFileToSend] = useState(null);

    async function fetchImage() {
        await axiosInstance.get('/api/newsimage/id', {
            params: {
                id: blog.id
            },
            // responseType:"blob"
        }).then(r => {
            console.log(r.data)
            const image = r.data;
            setImage(`data:${image.type};base64,${image.data}`);
            // setLoading(false);
        })
    }

    async function fetchData() {
        await axiosInstance.get('/api/newsfile/id', {
            params: {
                id: blog.id
            },
            // responseType:"blob"
        }).then(r => {
            console.log(r.data)
            setBlogFiles(r.data);
            // setLoading(false);
        })
    }

    useEffect(() => {

        fetchData()
        fetchImage()

    },[]);

    const showEditor = (e) => {

        if (localStorage.getItem('user') !== null) {
            if (isShown) {
                updateText(e).then(r => console.log(r));
            }
            setIsShown(!isShown);
        }
    };
    const uploadImage = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageToSend(event.target.files[0]);
    }

    const updateText = async(e) => {
        e.preventDefault();
        setIsTitleEditable(false);
        // setText(document.getElementById('tryEdit').innerHTML.toString())
        const stringHtml = document.getElementById('tryEdit').innerHTML.toString();
        const blogJson = JSON.stringify({
            title : title,
            text : stringHtml,
            isPublic : isPublic,
        })
        try {
            const response = await axiosInstance.patch('/api/news/update', blogJson, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: blog.id
                }
            })
            const data1 = await response;

        } catch(error) {
            console.log(error)
        }
    }


    function handleTitleChange(e) {
        setTitle(e.target.value);
    }

    function selectBlogStatus() {
        setIsPublic(!isPublic);
    }


    const handleFileSubmit = async(event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("file", fileToSend);
        formData.append("id", blog.id);
        try {

            const response = await axiosInstance.patch('/api/news/add/files', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })
            // setImage(`data:${response.data.type};base64,${response.data.data}`);
        } catch(error) {
            console.log(error)
        }
        axiosInstance.get('/api/newsfile/id', {
            params: {
                id: blog.id
            },
        }).then(r => {
            setBlogFiles(r.data);
        })

    }

    function uploadFile(e) {
        setFileToSend(e.target.files[0]);
        console.log(e.target.files[0])

    }

    const downloadFile = (file) => (e) => {
        e.preventDefault();

        axiosInstance.get(`/api/newsfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            console.log(res)
            FileDownload(res.data, file.file, file.type)
        })
    }

    const handleSubmit = async(event) => {
        // setIsShown(true);
        // console.log(selectedFile);
        event.preventDefault()
        const formData = new FormData();
        // selectedFile.forEach(file => {
        formData.append("image", imageToSend);
        formData.append("id", blog.id);
        // })
        try {
            const response = await axiosInstance.patch('/api/newsimage/update/image', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType:"blob",
            })

            const res = await response;
            if (res.status === 200) {
                console.log(res.data)
            }
            if (!(res.status === 200)) {
                console.log("nu e 200")
            }
            // setImage(`data:${response.data.type};base64,${response.data.data}`);
        } catch(error) {
            console.log(error)
        }
    }

    return (
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item" style={{padding: "0px", height: "130px", paddingTop: "50px" }}>
                                <form onSubmit={updateText} align="center" >
                                    <label htmlFor="filePicker3" style={{cursor:"pointer"}} >
                                        <div onClick={selectBlogStatus}>
                                            {isPublic
                                                ? <h1 className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "red"}}><i className="icon_circle-slelected"></i>Ascunde Blog</h1>
                                                : <h1 className="f_600 f_size_30 l_height30 t_color3 mb_50" style={{color: "green"}}><i className="icon_circle-slelected"></i>Publica Blog</h1>

                                            }
                                        </div>
                                        <input id="filePicker3" type="submit" name="file3" style={{display: "none"}}></input>
                                    </label>
                                </form>
                            </div>
                        )}
                        <br/>
                        {localStorage.getItem('role') === 'admin' && (
                            <div className="event_features_item " style={{padding: "0px", height: "130px", paddingTop: "30px"}}>
                                <form onSubmit={handleFileSubmit} style={{paddingLeft: "30px"}}>
                                    <label htmlFor="filePicker"  style={{cursor:"pointer"}}>
                                        <i className="icon_upload"></i>Incarca fisier&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                        <button type="submit" >
                                            Save
                                        </button>
                                    </label>

                                </form>
                                <form onSubmit={handleSubmit} style={{paddingLeft: "30px"}}>
                                    <label htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                        <i className="icon_upload"></i>Incarca imagine&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input id="filePicker1" type="file" name="file" onChange={uploadImage} style={{display: "none"}}></input>
                                        <button type="submit" >
                                            Save
                                        </button>
                                    </label>
                                </form>
                            </div>

                        )}
                        <br/>
                        <div className="event_features_item " style={{padding: "0px", height: "200px", paddingTop: "10px", paddingLeft: "30px"}}>
                            Documente atasate:
                            {blogFiles.length === 0 && (
                                <ul>
                                    <li>
                                        Nu exista atasamente.
                                    </li>
                                </ul>
                            )}
                            <ul>
                                {blogFiles.map((file) => {
                                    return(
                                        <li key={file.id} onClick={downloadFile(file)} style={{cursor:"pointer"}}>
                                            <i className="icon_document"></i>{file.file}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            {image !== null && (
                                <img className="img-fluid" src={image} alt="" />

                            )}
                            {image === null && (
                                <img className="img-fluid" src={require('../../img/blog-grid/img.png')} alt=""/>
                            )}
                            <div className="blog_content">
                                {/*<a href=".#">*/}
                                {/*    <h5 className="f_p f_size_20 f_500 t_color mb-30">{blog.title}</h5>*/}
                                {/*</a>*/}
                                {/*<p className="f_400 mb-30" align="justify">{blog.text}</p>*/}
                                {isTitleEditable && (
                                    <form onSubmit={updateText} align="center">
                                        <label htmlFor="filePicker1">
                                            <input id="filePicker1" type="text" placeholder={title} name="file1" onChange={handleTitleChange}>
                                            </input>
                                            <button type="submit" >
                                                Save
                                            </button>
                                        </label>
                                    </form>
                                )}
                                {!isTitleEditable && (
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30" onClick={() => setIsTitleEditable(!isTitleEditable)} align="center">{title}</h5>
                                )}

                                <div>
                                    {isShown && (
                                        <div>
                                            <Quill
                                                actualText={{
                                                    container: document.getElementById('tryEdit'),
                                                }}
                                            />
                                            <button type="submit" onClick={showEditor}>
                                                Save
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div id="tryEdit" className="content text-justify" onClick={showEditor} dangerouslySetInnerHTML={{__html: text}}>
                                    {/*<p>*/}
                                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogEdit;
