import React, {useEffect, useState} from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import AparitieEdit from "./AparitieEdit";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import DocMetodologieEdit from "./DocMetodologieEdit";

const PaginaDocMetodologieEdit = () => {
    const [doc, setDoc] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = window.location.pathname;
    // const history = this.props.location;
    const idParam = location.substring(10)

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/doc/id', {
                params: {
                    id: idParam
                },
                // responseType:"blob"
            }).then(r => {
                setDoc(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pagina modificare aparitie" Pdescription="Aceasta pagina este vizibila doar pentru admin."/>
            <DocMetodologieEdit doc={doc}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaDocMetodologieEdit;
