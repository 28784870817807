import React from 'react';
import Sticky from "react-stickynode";
import {Link, NavLink} from "react-router-dom";
import {useAtom} from "jotai";
import {userAtom} from "../components/SignInForm";
import {scroller} from 'react-scroll';


const Navbar = ({mClass, nClass, cClass, hbtnClass}) => {

    const [userFromAtom, setUserFromAtom] = useAtom(userAtom);

    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        scrollTarget(target);
    };

    function handleLogout() {
        localStorage.clear();
        setUserFromAtom(null);
    }

    return (

        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/' className="dropdown-toggle nav-link">Despre proiect</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item" onClick={() => scrollToHomePage('obiective-grup')}><NavLink exact className="nav-link" >Obiective & Grup Tinta</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('obiective-specifice')}><NavLink exact className="nav-link" >Obiective specifice ale proiectului</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('activitati')}><NavLink exact className="nav-link" >Activitati</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('documente-metodologii')}><NavLink className="nav-link" >Documente/Metodologii relevante</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('aparitii-presa')}><NavLink exact className="nav-link" >Aparitii in presa</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/Informare' className="dropdown-toggle nav-link">Informare</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item" onClick={() => scrollToHomePage('inscriere')}><NavLink exact className="nav-link" >Inscrierea in proiect</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('perioada')}><NavLink exact className="nav-link" >Perioada de desfasurare</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('evenimente')}><NavLink exact className="nav-link" >Evenimente</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('ajofmuri')}><NavLink exact className="nav-link" >AJOFM-uri partenere</NavLink></li>
                                        <li className="nav-item" onClick={() => scrollToHomePage('profilare')}><NavLink exact className="nav-link" >Profilare</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <NavLink to='/Formare-Anteprenoriala' className="dropdown-toggle nav-link">Formare Anteprenoriala</NavLink>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item" onClick={() => scrollToHomePage('cursul')}><NavLink exact className="nav-link" >Cursul de anteprenoriat</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Cursuri-De-Calificare">Cursuri</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Medierea-Muncii">Medierea muncii</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" to="/Sectiunea-Blog">Blog</NavLink></li>
                                {localStorage.getItem('role') === 'user' && (
                                    <li className="nav-item"><NavLink className="nav-link" to="/incarca-plan-de-afaceri" style={{color: "brown"}}>Incarca Plan Afaceri</NavLink></li>
                                )}

                                {localStorage.getItem('role') === 'admin' && (
                                    <li className="nav-item"><NavLink className="nav-link" to="/documente-admin" style={{color: "brown"}}>Documente Admin</NavLink></li>
                                )}
                            </ul>
                            {!localStorage.getItem('user') && (
                                <Link className={`btn_get btn_hover ${hbtnClass}`} to="/login">Login</Link>

                            )}
                            {localStorage.getItem('user') && (
                                <div onClick={handleLogout}>
                                    <Link className={`btn_get btn_hover ${hbtnClass}`} to="#" >Logout</Link>

                                </div>
                            )}
                            {window.location.pathname === "/" && (
                                <a className={`btn_get btn_hover ${hbtnClass}`} href="https://app.neetnomoresudmuntenia.ro/" target="_blank">Acces platforma</a>
                            )}
                        </div>
                    </div>
                </nav>
            </header>
         </Sticky>
    );
};

export default Navbar;
