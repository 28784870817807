import React, { Component } from 'react';
import {Link} from "react-router-dom";

class JobCompDinamicaManual extends Component {
    render() {
        let id = this.props.id;
        let title = this.props.title;
        let description = this.props.description;
        let link = this.props.link
        return (

            <div className="media">
                <div className="media-left">
                    <img
                        src={require("../../img/process/logo-slack.png")}
                        alt=""
                        width="40px"
                        height="40px"
                    />
                    <a href={link} target="_blank">
                        <i className="icon_link"></i>Vezi detalii
                    </a>
                </div>
                <div className="media-body">
                    <h3 className="h_head">{title}</h3>
                    <div dangerouslySetInnerHTML={{__html: description}} align="justify"></div>
                </div>
                {localStorage.getItem("role") === "admin" && (
                    <Link to={"/job/" + id}>
                        <i className="icon_pencil-edit"></i>
                    </Link>
                )}
            </div>

        );
    }
}

export default JobCompDinamicaManual;
