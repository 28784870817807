import React, {useState} from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";

const FormularIncarcare = () => {
    const [selectedFile, setSelectedFile] = useState();
    const [isShown, setIsShown] = useState(false);
    const [isShownError, setIsShownError] = useState(false);

    const handleChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        formData.append("user", localStorage.getItem("user"));

        try {
            const response = await axiosInstance.post('/api/files/upload/business-plan', formData, {
                headers: {"Content-Type": "multipart/form-data"}
            })
            if (!(response.status === 200)) {
                throw new Error(`Error! status: ${response.status}`);
            }
            setIsShown(true);
            setIsShownError(false);
        } catch(error) {
            console.log(error)
            setIsShown(false)
            setIsShownError(true)
        }
    }
    return (
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        <div className="col-lg-12">
                            {(!isShown && !isShownError) && (
                                <form onSubmit={handleSubmit} align="center">
                                    <label htmlFor="filePicker1" style={{cursor:"pointer"}}>
                                        <img src={require ("../../img/home4/addnew.png")} width="80px" height="80px" alt="" style={{cursor: "pointer"}}/>
                                        <input id="filePicker1" type="file" name="file1" onChange={handleChange} style={{display: "none"}}></input>
                                    </label>
                                    <h4>Incarca planul tau de afaceri</h4>
                                    {selectedFile && (
                                        <div>
                                            <button className="btn_three" type="submit">
                                                <i className="icon_upload" />&nbsp;Trimite
                                            </button>
                                        </div>
                                    )}
                                </form>
                            )}
                            {isShown && (
                                <div align="center">
                                    <h3 style={{color: 'green'}}><i className="icon_check_alt" /></h3>
                                    <h3>Planul de afaceri a fost trimis cu succes!</h3>
                                </div>
                            )}
                            {isShownError && (
                                <div align="center">
                                    <h3 style={{color: 'red'}}><i className="icon_error-triangle" /></h3>
                                    <h3>Incarcarea a esuat!</h3>
                                </div>
                            )}
                        </div>

                        {/*{!localStorage.getItem('user') && (*/}
                        {/*    <div className="col-lg-12">*/}
                        {/*        <div className="login_info">*/}
                        {/*            <form onSubmit={handleSubmit} className="login-form sign-in-form">*/}
                        {/*                <div className="form-group text_box">*/}
                        {/*                    <label className="f_p text_c f_400">E-mail:</label>*/}
                        {/*                    <input type="text" name="email" placeholder="E-mail"/>*/}
                        {/*                </div>*/}
                        {/*                <div className="form-group text_box">*/}
                        {/*                    <label className="f_p text_c f_400">Parola:</label>*/}
                        {/*                    <input type="password" name="password" placeholder="Parola"/>*/}
                        {/*                </div>*/}
                        {/*                <div className="d-flex justify-content-between align-items-center">*/}
                        {/*                    <button type="submit" className="btn_three">Conectare</button>*/}
                        {/*                </div>*/}
                        {/*                {showWrongCredentials && <span>Utilizator sau parola gresite</span>}*/}
                        {/*            </form>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{localStorage.getItem('user') && (*/}
                        {/*    <div className="col-lg-12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>*/}
                        {/*        <div className="login_info">*/}
                        {/*            <form onSubmit={handleLogout} className="login-form sign-in-form">*/}
                        {/*                <div className="d-flex justify-content-between" >*/}
                        {/*                    <button type="submit" className="btn_three">Deconectare</button>*/}
                        {/*                </div>*/}
                        {/*            </form>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}


                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormularIncarcare;
