import React from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import DocMetodologieCompDinamica from "./DocMetodologieCompDinamica";

const ListaDocumenteMetodologiiRelevante = ({docs, setDocs}) => {
    const addNewAppearance = async(e) => {
        e.preventDefault();
        const doc = JSON.stringify({
            title: "Titlu Document",
            isPublic: false,
        })
        try {

            const response = await axiosInstance.post('/api/doc/add', doc, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllAppearances();
    }

    const getAllAppearances = () => {

        axiosInstance.get('/api/doc/all').then((response) => {
            console.log(response.data)
            setDocs(response.data);
        });
    }
    return (
        <section className="event_schedule_area"  style={{paddingTop: "30px"}}>
            {localStorage.getItem("role") === "admin" && (
                <div style={{textAlign: "center"}}>
                    <img src={require ("../../img/home4/addnew.png")} width="60px" height="60px" alt="" style={{cursor: "pointer"}} onClick={addNewAppearance}/>
                    <p>Adauga</p>
                </div>

            )}
            {localStorage.getItem("role") === "admin" && (
                <div className="container">
                                {docs.map((doc) => {
                                    return (
                                        <DocMetodologieCompDinamica id={doc.id} title={doc.title} fileName={doc.fileName} key={doc.id}/>
                                    )
                                })}
                </div>
            )}
            {localStorage.getItem("role") !== "admin" && (
                <div className="container">
                                {docs.map((doc) => {
                                    if (doc.isPublic) {
                                        return (
                                            <DocMetodologieCompDinamica id={doc.id} title={doc.title} fileName={doc.fileName} key={doc.id}/>
                                        )
                                    }
                                })}
                </div>
            )}
        </section>
    );
};

export default ListaDocumenteMetodologiiRelevante;
