import React, {useState, useEffect} from 'react';
import CustomNavbar from "../../components/CustomNavbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import SecurityAction from "../../components/SecurityAction";
import DocumenteConcurs from "./DocumenteConcurs";
import ServiceSubscribe from "../../components/ServiceSubscribe";
import DocumenteConcursPlan from "./DocumenteConcursPlan";
import Sectitle from "../../components/Title/Sectitle";
import DocMetodologieCompDinamica from "./DocMetodologieCompDinamica";
import ScheduleTab from "../../components/scheduleTab";
import axiosInstance from "../../components/Helper/AxiosHelper";
import ListaDocumenteMetodologiiRelevante from "./ListaDocumenteMetodologiiRelevante";

const DocumenteMetodologiiRelevante = () => {

    const [docs, setDocs] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/doc/all').then((response) => {
            setDocs(response.data);
        })
    }, []);

    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <Sectitle Title="Documente / Metodologii relevante" TitleP=""
                          tClass="t_color3" sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-12">
                        <ListaDocumenteMetodologiiRelevante docs={docs} setDocs={setDocs}/>
                    </div>

                </div>
            </div>
        </section>
    )
};

export default DocumenteMetodologiiRelevante;
