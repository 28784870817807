import React from 'react';
import CustomNavbar from "../components/CustomNavbar";
import AgencyBanner from "../components/Banner/AgencyBanner";
import BannerData from "../components/Banner/BannerData";
import ServiceDetails from "../components/Service/ServiceDetails";
import Featuresitems from "../components/Features/Featuresitems";
import PrototypeService from "../components/Service/Sservice/PrototypeService";
import MarketingService from "../components/Service/MarketingService";
import ServiceData from "../components/Service/ServiceData";
import AgencyAbout from "../components/About/AgencyAbout";
import Features from "../components/Features/Features";
import AgencyAction from "../components/AgencyAction";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import ErpFeatures from "../components/Features/ErpFeatures";
import ChatClients from "../components/chatClients";
import EventFeatures from "../components/Features/EventFeatures";
import EvenimentePentruGT from "./Informare/EvenimentePentruGT";
import Navbar from "./Navbar";
import {Element} from "react-scroll";
import ErpFeaturesitem from "../components/Features/ErpFeaturesitem";

const Informare = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title="INFORMAREA TINERILOR NEETs" description="" classBG="informare"/>

                <div style={{paddingTop: '130px'}}>
                    <section className="erp_features_area_two sec_pad" >
                        <div className="container">
                            <Element id='inscriere' name='inscriere'>
                            <ErpFeaturesitem rowClass="align-items-center flex-row-reverse"
                                             image="young-business-woman-checking-sa.jpg"
                                             title="Inscrierea in proiect"
                                             text1="Apartenenta la grupul tinta se va realiza la intrarea in proiect (data la care persoana va beneficia pentru prima data de sprijinul oferit prin proiect) prin declaratie pe propria raspundere a persoanei din grupul tinta sau a persoanelor / institutiilor abilitate si/sau prin atasarea de documente doveditoare impreuna cu celelalte documente necesare inscrierii in grupul tinta. "
                                             text="Pentru inscrierea in proiect avem puse la dispozitie urmatoarele date de contact:"
                                             icon1Text="Email: neetnomoresm@gmail.com"
                                             icon2Text="Telefon: 0751.994.411 sau 0765.536.832"
                            />
                            </Element>
                            <Element id='perioada' name='perioada'>
                            <ErpFeaturesitem rowClass="align-items-center"
                                             image="calendar-page-pinned-calender-da.jpg"
                                             roundClass="red"
                                             title="Perioada de desfasurare"
                                             text1=""
                                             text="Proiectul va fi implementat in perioada iulie 2022 – decembrie 2023 si pe parcursul acestei perioade persoanele eligibile vor fi inregistrate, profilate, mediate si 186 vor urma cursuri de calificare. "
                                             icon1Text=""
                                             icon2Text=""
                            />
                            </Element>
                        </div>
                    </section>
                </div>

            <Element id='evenimente' name='evenimente'>
                <ChatClients/>
            </Element>
                <EvenimentePentruGT/>
            <Element id='ajofmuri' name='ajofmuri'>
                <EventFeatures />
            </Element>
            <Element id='profilare' name='profilare'>
                <AgencyAbout />
            </Element>

            <FooterTwo FooterData={FooterData}/>
        </div>
    )
};

export default Informare;
