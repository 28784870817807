import React from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import AparitieComponentaDinamica from "../DespreProiect/AparitieComponentaDinamica";
import EvenimentEdit from "./EvenimentEdit";
import EvenimentComponentaDinamica from "./EvenimentComponentaDinamica";

const ListaEvenimente = ({events, setEvents}) => {
    const addNewEvent = async(e) => {
        e.preventDefault();
        const appearance = JSON.stringify({
            title: "Titlu Eveniment",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false,
        })
        try {

            const response = await axiosInstance.post('/api/event/add', appearance, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllEvents();
    }

    const getAllEvents = () => {

        axiosInstance.get('/api/event/all').then((response) => {
            console.log(response.data)
            setEvents(response.data);
        });
    }
    return (
        <section className="event_schedule_area"  style={{paddingTop: "30px"}}>
            {localStorage.getItem("role") === "admin" && (
                <div style={{textAlign: "center"}}>
                    <img src={require ("../../img/home4/addnew.png")} width="60px" height="60px" alt="" style={{cursor: "pointer"}} onClick={addNewEvent}/>
                    <p>Adauga</p>
                </div>

            )}
            {localStorage.getItem("role") === "admin" && (
                <div className="container">
                    <div className="event_schedule_inner">
                        <div className="tab-content event_tab_content">
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {events.map((event) => {
                                    return (
                                        <EvenimentComponentaDinamica id={event.id} title={event.title} text={event.text} fileName={event.fileName} key={event.id}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {localStorage.getItem("role") !== "admin" && (
                <div className="container">
                    <div className="event_schedule_inner">
                        <div className="tab-content event_tab_content">
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {events.map((event) => {
                                    if (event.isPublic) {
                                        return (
                                            <EvenimentComponentaDinamica id={event.id} title={event.title} text={event.text} fileName={event.fileName} key={event.id}/>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </section>
    );
};

export default ListaEvenimente;
