import React from 'react';
import AparitieComponentaDinamica from "./AparitieComponentaDinamica";
import axiosInstance from "../../components/Helper/AxiosHelper";

const ListaAparitii = ({appearances, setAppearances}) => {

    const addNewAppearance = async(e) => {
        e.preventDefault();
        const appearance = JSON.stringify({
            title: "Titlu Aparitie",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isPublic: false,
        })
        try {

            const response = await axiosInstance.post('/api/appearance/add', appearance, {
                headers: { "Content-Type": "application/json" }
            })
            const data1 = await response;
        } catch(error) {
            console.log(error)
        }
        getAllAppearances();
    }

    const getAllAppearances = () => {

        axiosInstance.get('/api/appearance/all').then((response) => {
            console.log(response.data)
            setAppearances(response.data);
        });
    }
    return (
        <section className="event_schedule_area"  style={{paddingTop: "30px"}}>
            {localStorage.getItem("role") === "admin" && (
                <div style={{textAlign: "center"}}>
                    <img src={require ("../../img/home4/addnew.png")} width="60px" height="60px" alt="" style={{cursor: "pointer"}} onClick={addNewAppearance}/>
                    <p>Adauga</p>
                </div>

            )}
            {localStorage.getItem("role") === "admin" && (
                <div className="container">
                    <div className="event_schedule_inner">
                        <div className="tab-content event_tab_content">
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {appearances.map((appearance) => {
                                    return (
                                        <AparitieComponentaDinamica id={appearance.id} title={appearance.title} text={appearance.text} fileName={appearance.fileName} key={appearance.id}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {localStorage.getItem("role") !== "admin" && (
                <div className="container">
                    <div className="event_schedule_inner">
                        <div className="tab-content event_tab_content">
                            <div
                                className="tab-pane fade show active"
                                id="one"
                                role="tabpanel"
                                aria-labelledby="one-tab"
                            >
                                {appearances.map((appearance) => {
                                    if (appearance.isPublic) {
                                        return (
                                            <AparitieComponentaDinamica id={appearance.id} title={appearance.title} text={appearance.text} fileName={appearance.fileName} key={appearance.id}/>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ListaAparitii;
