import React from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import FileDownload from "js-file-download";
import {Link} from "react-router-dom";

const DocMetodologieCompDinamica = ({id, title, fileName}) => {

    const downloadFile  = (e) => {
        e.preventDefault();
        axiosInstance.get(`/api/docfile/download/${id}`, {
            responseType: "blob"
        }).then((res) => {
            const file = res.data;
            FileDownload(file, fileName, file.type)
        })
    }

    return(
        <>
            {localStorage.getItem("role") === "admin" && (
                <Link to={"/document/" + id}>
                    <i className="icon_pencil-edit"></i>
                </Link>
            )}
        {/*// < className="security_action_area">*/}
        {/*//     <img className="p_absoulte img_bg" src={require("../img/home-security/sheld_bg.png")} alt=""/>*/}
        <div className="container" style={{paddingTop: "50px", paddingBottom: "50px", borderRadius: "2px"}}>

            <div className="security_action_inner">
                <div className="media">
                    <img src={require("../../img/home-chat/book.png")} alt="" width="69px" height="80px"/>

                </div>
                <div className="media-body align-items-center">
                    {/*<h3><span>Want to see Sequr in action?</span>Hop on our daily webinar at 3:30 PM EST</h3>*/}
                    <h3 align="center">{title}</h3>
                </div>

                <div className="er_btn er_btn_two" style={{cursor: "pointer"}} onClick={downloadFile}>
                    <i className="icon_document"></i> Descarca
                </div>

                {/*<a href="/#" className="er_btn er_btn_two">Descarca</a>*/}
            </div>

        </div>
        </>

    )
};

export default DocMetodologieCompDinamica;
