import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import AgencyBanner from '../components/Banner/AgencyBanner';
import BannerData from '../components/Banner/BannerData';
import MarketingService from '../components/Service/MarketingService';
import ServiceData from '../components/Service/ServiceData';
import AgencyAbout from '../components/About/AgencyAbout';
import Features from '../components/Features/Features';
import MarketingTestimonial from '../components/Testimonial/MarketingTestimonial';
import AgencyAction from '../components/AgencyAction';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ServiceDetails from "../components/Service/ServiceDetails";
import Featuresitems from "../components/Features/Featuresitems";
import PrototypeService from "../components/Service/Sservice/PrototypeService";
import Sectitle from "../components/Title/Sectitle";
import DocMetodologieCompDinamica from "./DespreProiect/DocMetodologieCompDinamica";
import DocumenteMetodologiiRelevante from "./DespreProiect/DocumenteMetodologiiRelevante";
import AparitiiInPresa from "./DespreProiect/AparitiiInPresa";
import ScheduleTab from "../components/scheduleTab";
import Navbar from "./Navbar";
import { Element } from 'react-scroll';

const DigitalMarketing = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData} image="Banner  Sud Munteniau 1080 x 108.jpg" title="NEET NO MORE SUD MUNTENIA" description="Dezvoltarea Economiei din regiunea Sud Muntenia prin implicarea tinerilor Neets in societate – NEET NO MORE, Cod proiect 154910 " classBG="bg_color"/>

            <Element id='obiective-grup' name='obiective-grup'>
                <ServiceDetails />
            </Element>
            <Element id='obiective-specifice' name='obiective-specifice'>
                <section className="process_area bg_color" >
                    <div className="container">
                        <br/><br/><br/>
                        <Sectitle Title="Obiective specifice ale proiectului" TitleP=""
                                  tClass="t_color3" sClass="sec_title text-center"/>
                        <div className="features_info" style={{paddingBottom: "70px"}}>
                            {/*<img className="dot_img" src={require ('../img/home4/divider.png')} alt=""/>*/}

                            <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="millenial-girl-looks-positively-.jpg" iImg="icon01.png" ftitle="Informare si constientizare" descriptions="Cresterea nivelului de informare si constientizare privind situatia ocuparii in randul tinerilor Neets din regiunea Sud Muntenia, inregistrati la SPO sau directionati in vederea inregistrarii si profilarii, cu varsta intre 16 si 29 de ani si selectarea in grupul tinta a 371 de tineri NEETs nivel A,B,C si D, cu accent pe tinerii cu domiciliul in rural (minim 75 persoane, 20%) si pe tinerii apartinand minoritatii roma (minim 38 persoane, 10%)."/>
                            <Featuresitems rowClass="row" aClass="pl_100" fimage="photo-handsome-male-student-his-.jpg" iImg="icon02.png" ftitle="Competente profesionale" descriptions="Imbunatatirea nivelului de competente profesionale relevante pentru piata muncii pentru 186 de tineri NEETs B, C si D din regiunea Sud Muntenia, prin organizarea si desfasurarea de cursuri acreditate ANC de calificare, nivel 2 si 3 si cursuri ANC de specializare de-a lungul unei perioade de 16 luni,dintre cele 18 de implementare. "/>
                            <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="recruitment-jobs-career-hiring-v.jpg" iImg="icon3.png" ftitle="Stimularea ocuparii"
                                           descriptions="Stimularea ocuparii sustenabile a 371 de tineri NEETs A, B, C si D din Regiunea Sud Muntenia si insertia pe piata muncii a cel putin 160 de tineri, prin masuri specializate si personalizate de mediere pe piata muncii utilizând mijloace si instrumente fizice si electronice de punere în legatura cu angajatorii relevanti de pe piata muncii. "/>
                            <Featuresitems rowClass="row" aClass="pl_100" fimage="businessman-his-office-with-pilo.jpg" iImg="icon_04.png" ftitle="Sustinerea antreprenoriatului"
                                           descriptions="Sustinerea antreprenoriatului si stimularea demararii unei activitati independente pentru 15 tineri NEETs A cu abilitati si interese in domeniul antreprenorial din regiunea Sud Muntenia prin derularea unui program de sustinere antreprenoriala care include derularea unui curs acreditat ANC de competente antreprenoriale pentru 28 persoane, organizarea concursului de evaluare si selectare a celor 15 antreprenori, furnizarea de servicii de consiliere, consultanta si mentorat pentru acestia si acordarea a 15 subventii implementarea planurilor de afaceri."/>
                            {/*<Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_5.png" iImg="icon_05.png" ftitle="With efficiency to unlock more opportunities"*/}
                            {/*descriptions="Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy cras tickety-boo squiffy. Starkers dropped a clanger lurgy is cack excuse my French what a plonker blower.!"/>*/}
                            {/*<div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div>*/}
                        </div>
                    </div>
                </section>
            </Element>
            <Element id='activitati' name='activitati'>
                <PrototypeService />
            </Element>
            {/*<MarketingService ServiceData={ServiceData}/>*/}
            {/*<AgencyAbout ServiceData={ServiceData}/>*/}
            {/*<Features/>*/}
            {/*<MarketingTestimonial BannerData={BannerData}/>*/}

            <Element id='documente-metodologii' name='documente-metodologii'>
                <DocumenteMetodologiiRelevante/>
            </Element>
            <Element id='aparitii-presa' name='aparitii-presa'>
                <AparitiiInPresa/>
            </Element>


            <AgencyAction/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default DigitalMarketing;
