import React, {useState, useEffect} from 'react';
import Sectitle from "../../components/Title/Sectitle";
import ScheduleTab from "../../components/scheduleTab";
import axiosInstance from "../../components/Helper/AxiosHelper";
import ListaEvenimente from "./ListaEvenimente";

const EvenimentePentruGT = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/event/all').then((response) => {
            setEvents(response.data);
        })
    }, []);

    return(
        <ListaEvenimente events={events} setEvents={setEvents}/>
        // <section className="service_details_area sec_pad ">
        //     <div className="container">
        //         <Sectitle Title="Aparitii in presa" TitleP=""
        //                   tClass="t_color3" sClass="sec_title text-center mb_70"/>
        //         <div className="row">
        //             <div className="col-lg-12">
        //                 <ListaAparitii events={events} setEvents={setEvents}/>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
};

export default EvenimentePentruGT;
