import React, {Component} from 'react';

class AgencyAction extends Component {
    render(){
        return(
            <section className="action_area_three sec_pad">
                <div className="curved"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="action_content text-center">
                                {/*<h2 className="f_600 f_size_30 l_height45 mb_40">Ready to enjoy SaasLand and expenses?</h2>*/}
                                <p className="f_400 f_size_18" align="justify" style={{color: "white"}}>Proiectul este cofinanțat din Fondul Social European prin Programul Operațional Capital Uman 2014-2020, Axa prioritară 1 „Inițiativa locuri de muncă pentru tineri, POCU/991/1/3/154910 Operațiune compozită OS. 1.1, 1.2. Pentru informații detaliate despre celelate programe cofinanțate de Uniunea Europeană, vă invităm să vizitați www.fonduri-ue.ro. Conținutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene sau a Guvernului României.</p>
                            {/*    <a href=".#" className="about_btn white_btn wow fadeInLeft" data-wow-delay="0.3s">Just SaasLand IT</a>*/}
                            {/*    <a href=".#" className="about_btn wow fadeInRight" data-wow-delay="0.4s">Request a Demo</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AgencyAction;
