import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from "react-router-dom";
import axiosInstance from "../../components/Helper/AxiosHelper";
import Navbar from "../Navbar";
import Breadcrumb from "../../components/Breadcrumb";
import SignInForm from "../../components/SignInForm";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import AparitieEdit from "./AparitieEdit";


const PaginaAparitieEdit = () => {
    const [appearance, setAppearance] = useState();
    const [isLoading, setLoading] = useState(true);
    const location = window.location.pathname;
    // const history = this.props.location;
    const idParam = location.substring(10)
    console.log(idParam);

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get('/api/appearance/id', {
                params: {
                    id: idParam
                },
                // responseType:"blob"
            }).then(r => {
                setAppearance(r.data);
                setLoading(false);
            })
        }
        fetchData()

    }, []);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="body_wrapper">
            <Navbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pagina modificare aparitie" Pdescription="Aceasta pagina este vizibila doar pentru admin."/>
            <AparitieEdit appearance={appearance}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaAparitieEdit;
