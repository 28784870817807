import React, {Component} from "react";
import ErpIconitem from "../../components/Features/ErpIconitem";
import {Link} from "react-router-dom";


class DocumenteConcursPlan extends Component {
    render(){
        let {sClass} = this.props;
        return(
            <div className="container" style={{paddingBottom: "200px"}}>
                <div className={`subscribe_form_info text-center ${sClass}`}>
                    {/*<h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">Cursul de antreprenoriat</h2>*/}
                    <div style={{paddingRight: "50px"}}>

                        <div className="erp_content_two text-justify">
                            <p align="justify">Concursul de planuri de afaceri este deschis <b>EXCLUSIV</b> persoanelor inscrise in grupul tinta al proiectului, care indeplinesc cumulativ urmatoarele cerinte:
                            </p>
                        </div>
                        <div className="erp_content_two text-justify">
                                <ErpIconitem iconName="icon_globe-2" erpText={"Se incadreaza in categoria de grup tinta eligibil."}/>
                            {/*<a href="/#" className="erp_btn_learn">Learn More<i className="icon_globe-2"></i></a>*/}
                        </div>
                        <div className="erp_content_two text-justify">
                            <ErpIconitem iconName="icon_globe-2" erpText={"Intocmesc si depun dosar de candidatura in cadrul concursului de planuri de afaceri, plan care nu a mai fost finantat din alte surse provenite din Fondul Social European (pentru evitarea dublei finantari)."}/>
                            {/*<a href="/#" className="erp_btn_learn">Learn More<i className="icon_globe-2"></i></a>*/}
                        </div>
                        <div className="erp_content_two text-justify">
                            <ErpIconitem iconName="icon_globe-2" erpText={"In cazul finantarii planului de afaceri, isi asuma ca va fi reprezentantul legal al intreprinderii legal constituite in România, fiind direct responsabili de pregatirea si implementarea planului de afaceri si neactionand ca intermediar pentru planul de afaceri propus a fi finantat."}/>
                            {/*<a href="/#" className="erp_btn_learn">Learn More<i className="icon_globe-2"></i></a>*/}
                        </div>
                    </div>
                    <div style={{paddingTop: "50px", paddingRight: "50px"}}>
                        <div className="erp_content_two text-justify">
                            <h6 className="f_p f_600 f_size_20" align="left">Documente relevante:</h6>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_1_Fisa inscriere in proiect.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_A1.1_Met inscriere.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  A1.1_Metodologie de inscriere selectare si monitorizare gt.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_2_Declaratie dubla finantare.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_3_Scrisoare angajam_disponibilitate.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_4_Declaratie_status_curent.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_5_Declaratie_somer.docx
                            </a>
                        </div>
                        <div className="erp_content_two text-justify">
                            <a href="/#">
                                <i className="icon_document"></i>  Anexa_6_Declaratie roma.docx
                            </a>
                        </div>
                    </div>
                    <div style={{paddingTop: "50px"}}>
                        <Link to="/login" className="chat_btn btn_hover">
                            Incarca planul tau de afaceri
                        </Link>
                    </div>
                </div>

                {/*<div style={{paddingRight: "50px"}}>*/}
                {/*    <h6 className="f_p f_600 f_size_22 t_color3" align="left">Documente:</h6>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <a href="/#">*/}
                {/*        <i className="icon_document"></i>Descarca*/}
                {/*    </a>*/}
                {/*    <a href="/#">*/}
                {/*        <i className="icon_document"></i>Descarca*/}
                {/*    </a>*/}
                {/*    <a href="/#">*/}
                {/*        <i className="icon_document"></i>Descarca*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default DocumenteConcursPlan
