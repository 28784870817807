import React from 'react';
import PaginaIncarcaPlanDeAfaceri from "../IncarcarePlanDeAfaceri/PaginaIncarcaPlanDeAfaceri";

const ProtectedRouteUser = () => {
    if (localStorage.getItem('role') !== 'user') {
        window.location.pathname = '/';
    }
    return <PaginaIncarcaPlanDeAfaceri />
};

export default ProtectedRouteUser;
