import React from 'react';
import axiosInstance from "../../components/Helper/AxiosHelper";
import FileDownload from "js-file-download";
import {Link} from "react-router-dom";

const AparitieComponentaDinamica = ({id, title, text, fileName}) => {
    const downloadFile  = (e) => {
        e.preventDefault();
        axiosInstance.get(`/api/appearancefile/download/${id}`, {
            responseType: "blob"
        }).then((res) => {
            const file = res.data;
            FileDownload(file, fileName, file.type)
        })
    }

    return (
        <div className="media">
            <div className="media-left">
                <img
                    src={require("../../img/erp-home/cloud-computing.png")}
                    alt=""
                    width="40px"
                    height="40px"
                />

                {fileName && (
                    <div style={{cursor: "pointer"}} onClick={downloadFile}>
                        <i className="icon_document"></i> Descarca document
                    </div>
                )}
                {!fileName && (
                    <div>
                        <i className="icon_lock"></i> Nu exista document
                    </div>
                )}

            </div>
            <div className="media-body" >
                <h3 className="h_head">{title}</h3>
                <div dangerouslySetInnerHTML={{__html: text}} align="justify"></div>
            </div>
            {localStorage.getItem("role") === "admin" && (
                <Link to={"/aparitie/" + id}>
                    <i className="icon_pencil-edit"></i>
                </Link>
            )}
        </div>
    );
};

export default AparitieComponentaDinamica;
