import React from 'react';
import {Navigate} from "react-router-dom";
import PaginaDocMetodologieEdit from "../DespreProiect/PaginaDocMetodologieEdit";

const ProtectedDocRoute = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaDocMetodologieEdit />
    }
    window.location.pathname = "/";
};

export default ProtectedDocRoute;
