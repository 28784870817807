import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const Quill = ({actualText}) => {
    const { quill, quillRef } = useQuill();
    // const [value, setValue] = useState(actualText.container.innerHTML);
    React.useEffect(() => {
        if (quill) {
            quill.insertText(0, actualText.container.innerText, '', true);
            quill.on('text-change', () => {
                // console.log('Text change!');
                // console.log(quill.getText()); // Get text only
                // console.log(quill.getContents()); // Get delta contents
                // console.log(quill.root.innerHTML); // Get innerHTML using quill
                // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                // setValue(quillRef.current.firstChild.innerHTML);
                /* eslint-disable no-param-reassign */
                actualText.container.innerHTML = quillRef.current.firstChild.innerHTML;
            });
        }
    }, [
        quill,
        quillRef,
        actualText.container.innerText,
        actualText.container.innerHTML,
        actualText.container,
    ]);

    return (
        <div>
            <div ref={quillRef} />
        </div>
    );
};

export default Quill;
