import axios from 'axios'

export const baseURL = process.env.REACT_APP_BASE_URL
let headers = {}

if (localStorage.token) { headers.Authorization = `Bearer ${localStorage.token}` }

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers
});

export default axiosInstance;

// Usage :
// import AxiosInstance from 'axios-helper';
// axiosInstance.post('/home')
//     .then(res=>console.log(res))
//     .catch(err=>console.log(err))
