import React from 'react';
import Sectitle from "../Title/Sectitle";
import Pserviceslider from "./Sservice/Pserviceslider";

const ServiceDetails =()=> {
    return(
        <>
        <section className="service_details_area sec_pad">
            <div className="container">
                <Sectitle Title="Obiective & Grup Tinta" TitleP=""
                          tClass="t_color3" sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">Grup tinta</h6>
                                <p>Conditii de eligibilitate</p>
                            </div>
                            <div className="info_item">
                                <h6>Regiunea Sud Muntenia:</h6>
                                <p>judetele Arges, Calarasi, Dambovita, Ialomita, Giurgiu, Prahova si Teleorman</p>
                            </div>
                            <div className="info_item">
                                <h6>Varsta</h6>
                                <p>intre 18 si 29 ani</p>
                            </div>
                            <div className="info_item">
                                <h6>Studii Actuale</h6>
                                <p>Nu sunt la nici o forma de invatamant</p>
                            </div>
                            <div className="info_item">
                                <h6>Studii finalizate</h6>
                                <p>liceu, facultate, masterat, sau chiar si scoala profesionala</p>
                            </div>
                            <div className="info_item">
                                <h6>Status ocupational</h6>
                                <p>Nu sunt angajati, nu au fost niciodata sau acum sunt someri</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <br/>
                                <p className="f_400 f_size_22" align="justify">
                                    <b>OBIECTIVUL GENERAL</b> al proiectului consta in dezvoltarea si implementarea unui ansamblu de masuri si actiuni concrete privind cresterea nivelului de ocupare si imbunatatirea competentelor pentru 371 de tineri NEETs nivel de ocupabilitate A, B, C si D cu varsta intre 16 si 29 de ani inregistrati si profilati la SPO (Serviciul Public de Ocupare), cu rezidenta in regiunea de dezvoltare Sud Muntenia, cu accent pe tinerii cu domiciliul in mediul rural (minim 75 persoane, 20%), pe tinerii apartinand minoritatii roma (minim 38 persoane, 10%).
                                </p>
                                <br/>
                                <p className="f_400 f_size_22" align="justify">
                                    Astfel, se vizeaza imbunatatirea nivelului de competente profesionale relevante pentru piata muncii pentru 186 de tineri NEETs B, C si D, stimularea ocuparii sustenabile pe piata muncii a 371 de tineri NEETs A, B, C si D si ocuparea efectiva a cel putin 160 de tineri NEETs, stimularea demararii unei activitati independente pentru 15 tineri NEETs nivel ocupabilitate A cu valente antreprenoriale finantati cu subventii (micro-grant-uri) de maximum 25.000 Euro/subventie.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default ServiceDetails;
