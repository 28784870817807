import React from 'react';
import PaginaAparitieEdit from "../DespreProiect/PaginaAparitieEdit";

const ProtectedAparitiiRoute = () => {
    if (localStorage.getItem('role') === 'admin') {
        return <PaginaAparitieEdit />
    }
    window.location.pathname = "/";
};

export default ProtectedAparitiiRoute;
