import React from 'react';
import PaginaDocumenteAdmin from "../DocumenteAdmin/PaginaDocumenteAdmin";

const ProtectedDocumenteAdmin = () => {
    if (localStorage.getItem('role') !== 'admin') {
        window.location.pathname = "/";
    }
    return <PaginaDocumenteAdmin />
};

export default ProtectedDocumenteAdmin;
