import React, {Component} from 'react';
import Slider from 'react-slick';
import Sectitle from "../Title/Sectitle";
class AgencyAbout extends Component {
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 1500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        // let ServiceData = this.props.ServiceData;
        return(
            <section style={{paddingTop: "70px", paddingBottom: "100px"}}>
            <Sectitle Title="Informatii despre profilare" TitleP=""
                      tClass="t_color3" sClass="sec_title text-center mb_70"/>

        <section className="agency_about_area d-flex bg_color">
                <div className="col-lg-6 about_content_left ">
                    <div className="about_content mb_30">
                        {/*<h2 className="f_size_30 f_700 l_height45 mb_20">We craft marketing & digital products that grow businesses.</h2>*/}
                        <p className="f_size_15 f_400" align="justify" style={{marginBottom: '0'}}>Daca persoanele eligibile nu sunt deja profilate, in prima etapa acestea vor transmite o seride de documente din lista de mai jos, care nu se rezuma la aceasta lista (in functie de cerintele fiecarei institutii locale): </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• Copie CI, copie carnet de conducere</p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• Copie diplome studii</p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• Copie certificate de calificare, TIC si limbi straine </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• CV </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• Alte documente relevante care sa certifice competente si abiltati </p>
                        <p className="f_size_15 f_400" align="justify" style={{marginBottom: '0'}}>Ca urmare a inregistrarii si profilarii la SPO si a inscrierii si selectarii in grupul tinta al proiectului, tinerii NEETs vor putea beneficia de servicii in cadrul proiectului in conformitate cu categoria in care sunt incadrati din punct de vedere al ocupabilitatii, respectiv:  </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• A - „usor ocupabil”,  </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• B- „mediu ocupabil”,  </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• C- „greu ocupabil” si  </p>
                        <p className="f_size_15 f_400" style={{marginBottom: '0'}}>• D - „foarte greu ocupabil”</p>
                        {/*<a href=".#" className="about_btn">Meet Your Team</a>*/}
                    </div>
                </div>
                <div className="col-lg-6 about_img">
                    <a href=".#" className="pluse_icon"><i className="ti-plus"></i></a>
                    <Slider className="about_img_slider" {...settings}>
                        <div className="item">
                            <div className="about_item w100">
                                <img src={require('../../img/home4/woman-selecting-pictures-people.jpg')} alt=""/>
                                <div className="about_text align-items-end" >
                                    <span className="br"></span>
                                    {/*<h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext1}</h5>*/}
                                    <h2><b>Informatii despre profilare</b></h2>
                                </div>
                            </div>
                            {/*<div className="about_item w55">*/}
                            {/*    <img src={require('../../img/home4/team2.jpg')} alt=""/>*/}
                            {/*    <div className="about_text text_two">*/}
                            {/*        <span className="br"></span>*/}
                            {/*        <h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext2}</h5>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="about_item w55">*/}
                            {/*    <img src={require('../../img/home4/team2.jpg')} alt=""/>*/}
                            {/*    <div className="about_text text_two">*/}
                            {/*        <span className="br"></span>*/}
                            {/*        <h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext3}</h5>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="item">*/}
                        {/*    <div className="about_item w45">*/}
                        {/*        <img src={require('../../img/home4/team1.jpg')} alt=""/>*/}
                        {/*        <div className="about_text">*/}
                        {/*            <span className="br"></span>*/}
                        {/*            <h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext1}</h5>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="about_item w55">*/}
                        {/*        <img src={require('../../img/home4/team2.jpg')} alt=""/>*/}
                        {/*        <div className="about_text text_two">*/}
                        {/*            <span className="br"></span>*/}
                        {/*            <h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext2}</h5>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="about_item w55">*/}
                        {/*        <img src={require('../../img/home4/team2.jpg')} alt=""/>*/}
                        {/*        <div className="about_text text_two">*/}
                        {/*            <span className="br"></span>*/}
                        {/*            <h5 className="f_size_18 l_height28 mb-0">{ServiceData.agtext3}</h5>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Slider>
                </div>
            </section>
            </section>
        )
    }
}
export default AgencyAbout;
