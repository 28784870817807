import React from 'react';
import EventFeaturesItem from './EventFeaturesItem';
import Sectitle from "../Title/Sectitle";

const EventFeatures =()=>{
    return(
        <section className="event_features_area" style={{paddingBottom: "150px"}}>
            <div className="container">
                <div className="hosting_title security_title text-center">
                    {/*<h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>AJOFM-uri partenere</span></h2>*/}
                    <Sectitle Title="AJOFM-uri partenere" TitleP=""
                              tClass="t_color3" sClass="sec_title text-center"/>
                    <p align="justify">In vederea mobilizarii tinerilor NEETs deja inregistrati la SPO in bazele de date ANOFM/AJOFM spre a se inscrie in grupul tinta, se vor incheia acorduri cadru de parteneriat cu AJOFM-uri din judetele care fac parte din regiunea Sud Muntenia:</p>
                </div>
                <div className="row event_features_inner">
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Prahova "
                                       p1="Ploiesti, str Nicolae Iorga nr 1 "
                                       p2="Nr. Tel:  0244 577 380 "
                                       p3="Nr. Fax: 0244 577 380 "
                                       p4="Email: ajofm@ph.anofm.ro  "
                                       p5="Pagina web: www.prahova.anofm.ro "
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Arges "
                                       p1="Argeş, B-Dul. Republicii Nr. 11"
                                       p2="Nr. Tel: 0248 222 415"
                                       p3="Nr. Fax: 0248 222 582"
                                       p4="Email: ajofm@ag.anofm.ro"
                                       p5="Pagina web: www.arges.anofm.ro"
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agentia Judeteana Pentru Ocuparea Fortei de Munca Calarasi  "
                                       p1="Calarasi, Strada 13 Decembrie Nr. 12  "
                                       p2="Telefon: +40 242 316 542"
                                       p3="Fax: +40 242 331 693"
                                       p4="E-mail: calarasi@ajofm.anofm.ro "
                                       p5="Pagina web: www.calarasi.anofm.ro  "
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agenţia Judeţeană pentru Ocuparea Forţei de Muncă Dambovita "
                                       p1="Targoviste, Strada Tudor Vladimirescu Nr. 1A  "
                                       p2="Nr. Telefon: 0245 615 932  "
                                       p3="Nr. Fax: 0245 620 635  "
                                       p4="E-mail: dambovita@ajofm.anofm.ro "
                                       p5="Pagina web: www.dambovita.anofm.ro  "
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agentia Judeteana Pentru Ocuparea Fortei de Munca Teleorman  "
                                       p1="Teleorman, Strada Dunării Nr. 1  "
                                       p2="Telefon: 0247/312.944  "
                                       p3="Fax: 0247/310.155  "
                                       p4="E-mail: teleorman@ajofm.anofm.ro "
                                       p5="Pagina web: www.teleorman.anofm.ro  "
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agentia Judeteana Pentru Ocuparea Fortei de Munca Giurgiu  "
                                       p1="Giurgiu, Soseaua Bucureşti, Bl. 202/5D, Bl.202/5D, mezanin si parter "
                                       p2="Telefon: 0246 230 613"
                                       p3="Telefon: 0246 230 606  "
                                       p4="E-mail: giurgiu@ajofm.anofm.ro "
                                       p5="Pagina web: www.giurgiu.anofm.ro  "
                                       btn="Buy Tickets"/>

                    <EventFeaturesItem url=""
                                       image="leader.png"
                                       title="Agentia Judeteana Pentru Ocuparea Fortei de Munca Ialomita "
                                       p1="Slobozia, Strada Lacului Nr. 10, Et. 3  "
                                       p2="Telefon: +40 243 230 999  "
                                       p3="ax: +40 243 231 785  "
                                       p4="E-mail: ajofm@il.anofm.ro   "
                                       p5="Pagina web: www.ialomita.anofm.ro"
                                       btn="Buy Tickets"/>
                    {/*<EventFeaturesItem url="" image="meetup.png"  title="Day Long Meetup" ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."*/}
                    {/*btn="Buy Tickets"/>*/}
                    {/*<EventFeaturesItem url="" image="chat.png"  title="Ask Questions" ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."*/}
                    {/*btn="Buy Tickets"/>*/}
                </div>
            </div>
        </section>
    )
}
export default EventFeatures;
