import React, {useEffect, useState} from 'react';
import CustomNavbar from "../../components/CustomNavbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import ScheduleTab from "../../components/scheduleTab";
import Sectitle from "../../components/Title/Sectitle";
import ListaAparitii from "./ListaAparitii";
import axiosInstance from "../../components/Helper/AxiosHelper";

const AparitiiInPresa = () => {

    const [appearances, setAppearances] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/appearance/all').then((response) => {
            setAppearances(response.data);
        })
    }, []);

    return(
        <section className="service_details_area sec_pad ">
            <div className="container">
                <Sectitle Title="Aparitii in presa" TitleP=""
                          tClass="t_color3" sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-12">
                        <ListaAparitii appearances={appearances} setAppearances={setAppearances}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default AparitiiInPresa;
